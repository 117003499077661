
<script setup lang="ts">
    import { computed } from "vue";
    import { utils } from "o365-utils";

    import colors from "./colors.ts";
    import MPill from "./components.Pill.vue";

    export interface IPill {
        cssColor: string;
        color: string;
        text: string;
    };

    export interface IProps {
        href?: string;
        type?: string;
        title?: string;
        pills?: Array<IPill>;
        startDate?: Date | string;
        finishDate?: Date | string;
        onClick?: any;
        shadow?: "none" | "sm" | "md" | "lg";
        ribbonColor?: string;
        strikethrough?: boolean;
    };

    export interface IEmits {
        (e: "click"): void;
    };

    const props = defineProps<IProps>();
    const emit = defineEmits<IEmits>();

    const isClickable = computed(() => props.href || props.onClick);
    const ribbonPill = computed(() => props.pills?.[0]);
    const startDate = computed(() => utils.getValidDate(props.startDate));
    const finishDate = computed(() => utils.getValidDate(props.finishDate));

    const ribbonColor = computed(() => {
        if (props.ribbonColor) {
            return props.ribbonColor;
        } else if (ribbonPill.value != null) {
            let ribbonPillColor =  ribbonPill.value.color;
            return colors[ribbonPillColor].background;
        }
    });

    const style = computed(() => {
        const style = {};
        if (props.shadow !== "none") {
            style.boxShadow = "2px 4px 8px rgba(0%, 0%, 0%, 5%)";
        }
        return style;
    });
</script>

<template>
    <component :is="props.href && 'a' || 'div'" class="afm-card" :class="{ 'afm-card-clickable': isClickable }" :style="style" :href="props.href" v-bind="$attrs" @click="(...args) => emit('click', ...args)">

        <template v-if="ribbonColor">
            <div class="flex-shrink-0" :style="{ 'background-color': ribbonColor, width: '6px', 'border-radius': '0.5rem 0 0 0.5rem' }">
            </div>
        </template>
        <div class="flex-grow-1" style="padding: 0.75rem;">
            <template v-if="type">
                <div class="font-n4 uppercase tracking-4 text-muted">
                    {{ type }}
                </div>
            </template>
            <template v-if="title">
                <div class="font-1 fw-medium" :class="{ 'text-primary': isClickable, 'text-decoration-line-through': strikethrough }">
                    {{ title }}
                </div>
            </template>
            <template v-if="pills?.length">
                <div class="d-flex gap-1" style="margin-left: -0.125rem;">
                    <template v-for="pill in pills">
                        <MPill size="sm" :cssColor="pill.cssColor" :color="pill.color" :text="pill.text" uppercase />
                    </template>
                </div>
            </template>
            <slot />
            <template v-if="startDate || finishDate">
                <div class="d-flex justify-content-end">
                    <div class="d-flex align-items-center gap-1">
                        <div>{{ $formatDate(startDate) }}</div>
                        <i class="bi bi-arrow-right" />
                        <div>{{ $formatDate(finishDate) }}</div>
                    </div>
                </div>
            </template>
        </div>
    </component>
</template>

<style scoped>
    .afm-card {
        display: flex;
        position: relative;

        color: black;
        background-color: white;
        background-color: var(--bs-white);
        border: 1px solid rgb(90%, 90%, 90%);
        border: 1px solid var(--bs-gray-300);

        text-decoration: none;

        border-radius: 0.5rem;
    }

    .afm-card-clickable {
        transition: background 200ms ease-in-out;
        &:active {
            transition: background 0ms;
            background-color: var(--bs-gray-300);
        }
    }

    .afm-card:before {
        position: absolute;
        inset: 0;
        background-color: rgb(50%, 50%, 50%);
        opacity: 0%;
        transition: all 200ms ease-in-out;
    }
    .afm-card:hover:before {
        opacity: 10%;
    }
</style>
